<template>
  <div>대시보드-RVI-EW</div>
</template>

<script>

export default {
  name: 'dashboard',
  data() {
    return {}
  },

  computed: {

  },

  created() {
    //  this.getTMData()
  },

  mounted() {
  },

  methods: {
  },
}
</script>

<style>
</style>


