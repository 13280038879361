<template>
  <div>
    <div class="col-auto f-l pl-0 ssb-select-box">
      <select class="custom-select" v-model="selectValue" placeholder="-- 선택 --">
        <option v-for="(item, index) in selectBoxs" :key="index" :value="item.value">{{ item.name }}</option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'select-box',
  props: {
    selectBoxs: {
      type: Array,
      required: true,
      default: () => []
    }, // 셀렉트 박스 정보
    defaultSelectBoxValue: {
      type: String,
      required: true,
      default: ''
    } ,// 셀렉트 박스 기본 값
    defaultSelectBoxText: {
      type: String,
      required: false,
      default: ''
    } // 셀렉트 박스 기본 값
  },
  data() {
    return {
      selectValue: this.defaultSelectBoxValue
    }
  },
  watch: {
    selectValue: {
      handler() {
        this.onEmitData()
      },
      immediate: true
    }
  },
  mounted() {
    this.onEmitData()
  },
  methods: {
    /**
     * 부모로 데이터를 전송하는 함수
     *
     * @method onEmitData
     */
    onEmitData() {
      this.$emit('onChangeSearchBox', {
        selectValue: this.selectValue
      })
    },
    setSelectedValue(value){
      this.selectValue = value;
    }
  }
}
</script>

<style scope>
.ssb-select-box {
width: 25%;
max-width: 150px;
min-width: 150px;
padding-left: 0px;
float: left;
}
.ssb-search {
float: left;
max-width: 600px;
}
</style>
