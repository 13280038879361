<!-- 페이지네이션 -->
<template>
  <div>
    <nav>
      <ul class="pagination justify-content-center" v-show="currentPage > 0">
        <li class="page-item" v-show="currentDivision !== 1">
          <a class="page-link" href="javascript:void(0)" @click="onClickNextDivision(-1)">
            <i class="fa fa-angle-double-left fa-lg"></i>
          </a>
        </li>
        <li class="page-item" v-show="currentPage !== 1">
          <a class="page-link" href="javascript:void(0)" @click="onClickNextPage(-1)">
            <i class="fa fa-angle-left fa-lg"></i>
          </a>
        </li>
        <li
          :class="['page-item', item === currentPage? 'active' : '']"
          v-for="item in pages"
          :key="item"
          @click="onClickPage(item)"
        >
          <a class="page-link" href="javascript:void(0)">{{ item }}</a>
        </li>

        <li class="page-item" v-show="currentPage !== this.totalPage">
          <a class="page-link" href="javascript:void(0)" @click="onClickNextPage(1)">
            <i class="fa fa-angle-right fa-lg"></i>
          </a>
        </li>
        <li class="page-item" v-show="currentDivision !== this.totalDivisionCount">
          <a class="page-link" href="javascript:void(0)" @click="onClickNextDivision(1)">
            <i class="fa fa-angle-double-right fa-lg"></i>
          </a>
        </li>
        <li class="page-item" v-show="showAllBtn && totalPage > 1">
          <a href="javascript:void(0)" class="btn btn-secondary btn-sm" @click="onClickShowAll()">전체보기</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'paging-nations',
  props: {
    totalContentCount: {
      type: Number,
      required: true,
      default: 0
    }, // 총 컨텐츠 갯수
    rowPerPage: {
      type: Number,
      required: true,
      default: 10
    }, // 한 페이지당 보여줄 컨텐츠 갯수
    showAllBtn:{
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      currentPage: 1, // 현재 페이지
      currentDivision: 1, // 현재 구역
      totalPage: 0, // 총 페이지 수
      totalDivisionCount: 0, // 총 페이지 구역 수
      rowPerDivision: 5, // 한구역당 보여질 페이지 수
      pages: [] // 페이지 번호
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    totalContentCount: {
      handler() {
        this.init()
      }
    },
    currentPage: {
      handler() {
        //console.log('Pagingnations:: 현재 페이지: ', this.currentPage)
        this.onEmitClickPage()
      }
    }
  },
  methods: {
    /**
     * 초기화 함수
     * 
     * @method init
     */
    init() {
      this.totalPage = Math.ceil(this.totalContentCount / this.rowPerPage)
      this.totalDivisionCount = Math.ceil(this.totalPage / this.rowPerDivision)

      /*
      console.log(
        '총 컨텐츠 갯수: ',
        this.totalContentCount,
        '총 페이지 수: ',
        this.totalPage,
        '총 구역 수: ',
        this.totalDivisionCount
      )
      */
     
      // 최초 페이지 번호 셋팅(1 ~ 5 페이지 까지)
      this.setPages()
      this.onEmitClickPage(false)
    },
    /**
     * 부모로 전달하는 함수
     *
     * @method onEmitClickPage
     * @param {Boolean} isExecGetData 서버에서 데이터를 가져오는 함수를 실행할지 유무
     */
    onEmitClickPage(isExecGetData = true) {
      this.$emit('onClickPage', {
        pageNum: this.currentPage,
        isGetData: isExecGetData
      })
    },
    /**
     * 화면에 그릴 페이지 목록을 가져오는 함수
     *
     * @method setPages
     * @return results 페이지 목록
     */
    setPages() {
      let totalPages = []
      let startIndex = (this.currentDivision - 1) * this.rowPerDivision + 1
      let page = this.rowPerDivision * this.currentDivision < this.totalPage ? this.rowPerDivision * this.currentDivision : this.totalPage

      for (let index = startIndex; index <= page; index++) {
        totalPages.push(index)
      }

      this.pages = totalPages
    },
    /**
     * 다음/이전 구역으로 넘어가는 클릭 함수
     *
     * @method onClickNextDivision
     * @param {Number} nextDivisionValue
     */
    onClickNextDivision(nextDivisionValue) {
      this.currentDivision = this.currentDivision + nextDivisionValue

      // 현재 페이지 셋팅
      this.currentPage =
        this.currentDivision * this.rowPerDivision - this.rowPerDivision + 1

      this.setPages()
    },
    /**
     * 페이지 클릭 함수
     *
     * @method onClickPage
     * @param {Number} pageNum 클릭한 페이지 넘버
     */
    onClickPage(pageNum) {
      this.currentPage = pageNum
    },
    /**
     * 다음/이전 페이지로 넘어가는 클릭 함수
     *
     * @method onClickNextPage
     * @param {Number} paramNextPageNum 다음/이전 으로 넘어갈 페이지 값
     */
    onClickNextPage(paramNextPageNum) {
      let nextPageNum = this.currentPage + paramNextPageNum

      if (nextPageNum === 0) {
        this.currentPage = 1
      } else if (nextPageNum > this.totalPage) {
        this.currentPage = this.totalPage
      } else {
        this.currentPage = nextPageNum
      }

      /*if (nextPageNum === 0) {
        this.currentPage = 1
      } else if (
        nextPageNum ===
        this.totalDivisionCount * this.rowPerDivision + 1
      ) {
        this.currentPage = this.totalDivisionCount * this.rowPerDivision
      } else {
        this.currentPage = nextPageNum
      }*/

      // 현재 구역에 없을 경우 구역을 옮긴다
      if (!this.pages.includes(this.currentPage)) {
        this.currentDivision = this.currentDivision + paramNextPageNum
        this.setPages()
      }
    },
    /**
     * 전체보기 클릭 함수
     *
     * @method onClickShowAll
     */
    onClickShowAll() {
      this.currentPage = 0
      this.onEmitClickPage(true)
    },
  }
}
</script>