<!-- 상세보기모달 -->
<template>
    <div>
        <modal ref="modal" :title="mode === 's' ? '부가서비스 등록' : '부가서비스 수정'" :width="600">
            <template v-slot:body>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">서비스 타입</label>
                            <div class="col-sm-8">
                                <select class="form-control" v-model="userData.serviceType" placeholder="서비스 타입을 선택하세요." @click="onChangeServiceType()">
                                    <template v-for="(item, index) in serviceTypeList">
                                        <option :value="index" :key="index">{{ item }}</option>
                                    </template>
								</select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">상위 부가서비스</label>
                            <div class="col-sm-8">
                                <select class="form-control" v-model="userData.parentSeq" placeholder="상위 부가서비스를 선택하세요.">
                                    <option value="0">&nbsp;</option>
                                    <template v-for="(item, index) in parentServiceList">
                                        <option :value="item.seq" :key="index">{{ item.name }}</option>
                                    </template>
								</select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">부가서비스명</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" placeholder="부가서비스명을 입력하세요." v-model="userData.name" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">가격</label>
                            <div class="col-sm-8">
                                <input type="number" class="form-control" placeholder="가격을 입력하세요." v-model="userData.unitPrice" />
                            </div>
                        </div>
					</div>
                </div>
            </template>
            <template v-slot:footer>
				<button type="button" class="btn btn-primary" @click="onClickOK">저장</button>
                <button type="button" class="btn btn-secondary" @click="onClickClose">닫기</button>
            </template>
        </modal>

    </div>
</template>

<script>
import Modal from '../Modal'
import { mapState } from 'vuex'

export default {
    components: {
        Modal,
    },
    data() {
        return {
            isUpdate          : false,
            mode              : 's',
            userInfo          : {},
            userData          : {},
            serviceTypeList   : {},
            seq               : 0,
            parentServiceList : []
        }
    },
    created() {
        const userInfo = this.$store.getters.getUserInfo()
        if (_.isEmpty(userInfo)) {
            this.$router.replace({
                path: '/'
            })
        }
        this.userInfo = userInfo
        this.serviceTypeList = this.$store.getters.getServiceType
    },
    computed: {
        ...mapState(['domainUrl']),
        ...mapState(['imageUrl'])
    },
    methods: {
        onShow(payload) {
            this.userData      = {}
            this.mode          = 's'
            this.seq           = 0
            this.userData.unitPrice = 0
            this.userData.parentSeq = 0

			if(payload && payload.seq){
                this.mode = 'e'
                this.seq = payload.seq    
                this.getTMData()
			}
            this.$refs.modal.onShow()
        },

        /**
         * 데이터를 가져오는 함수
         *
         * @method getShopSetting
         * @param {boolean} isSearchBtnClick 검색 버튼 눌렀는지 여부
         */
         getTMData() {
            this.$connect(
				`/api/additionalService/${this.seq}`,
                'GET'
            )
            .then(bodyData => {
                this.userData = bodyData.list
                this.onChangeServiceType()
            })
            .catch(error => {
                console.log("error", error);
            })
        },

        /**
         * 모달닫기
         *
         * @method onClickClose
         */
        onClickClose() {
            this.$refs.modal.onClose()
            this.$emit('onCloseContentSave', {
                isUpdate: this.isUpdate
            })
        },

		async onClickOK() {
            if(
                !this.userData.serviceType || 
                !this.userData.name
            ){
                alert("필요한 정보를 입력해주세요.")
                return false
            }

            try {
                this.userData.parentSeq = this.userData.parentSeq?this.userData.parentSeq:0

                if(this.mode === 's'){
                    this.userData.createUserId = this.userInfo.id
                }else if(this.mode === 'e'){
                    this.userData.modifyUserId = this.userInfo.id
                }

                this.$connect(
                    `/api/additionalService/save`,
                    'POST', this.userData
                )
                .then(bodyData => {
                    this.isUpdate = true
                    this.onClickClose()
                })

            } catch (error) {
                console.error("Error:", error);
                alert("설정을 저장하는 중 오류가 발생했습니다.");
            }

		},

        /**
         * 서비스 타입에 맞는 부가서비스 parentSeq가 0인것 불러오기
         *
         * @method onClickClose
         */
        onChangeServiceType(){
            if(this.userData.serviceType > 0){
                this.parentServiceList = []

                this.$connect(
                    `/api/additionalService/parentList/${this.userData.serviceType}`,
                    'GET',
                )
                .then(bodyData => {
                    this.parentServiceList = bodyData.list
                })
            }
        }

    },
}
</script>