<template>
  <div>
    <div
      :class="{ modal: true, fade: true, show: show }"
      :style="{ display: show ? 'block' : 'none' }"
      id="modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="Modal"
      
    >
    <!--aria-hidden="true"-->
      <!-- 
        드래그 라이브러리 npm 주소: https://www.npmjs.com/package/vue-draggable-resizable
        드래그 라이브러리 예제 소스: https://mauricius.github.io/vue-draggable-resizable/?path=/story/basic--basic-component
        설명: 모달을 컴포넌트를 사용하는 곳에서 isDrag라는 파라미터로 드래그 여부를 결정합니다.
          isDrag가 true 이면 드래그 가능, false 일 경우 드래그가 불가능합니다.
        드래그 방법: 모달을 띄운 후 헤더 부분을 마우스로 드래그 하시면 됩니다.
      -->
      <vue-draggable-resizable
        :drag-handle="'.drag-handle'"
        class="modal-dialog modal-dialog-centered"
        role="document"
        :style="{
          width: `100%`,
          'max-width': `${width}px`,
          position: 'relative !important',
        }"
      >
        <div class="modal-content">
          <div class="modal-header" :class="{ 'drag-handle': isDrag }">
            <h5 class="modal-title" id="modal" v-html="title"></h5>
            <button
              type="button"
              class="close"
              @click="onClose"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div
            class="modal-body"
            ref="modalBody"
            :style="{ 'padding-bottom': `${paddingBottom}px` }"
          >
            <slot name="body"></slot>
          </div>
          <div class="modal-footer">
            <div class="col-12 text-center">
              <slot name="footer"></slot>
              <!--
              <button type="button" class="btn btn-primary" data-dismiss="modal">확인</button>
              <button type="button" class="btn btn-secondary" data-dismiss="modal">닫기</button>
              <button type="button" class="btn btn-third" data-dismiss="modal">수정</button>
              <button type="button" class="btn btn-default" data-dismiss="modal">저장</button>
              -->
            </div>
          </div>
        </div>
      </vue-draggable-resizable>
    </div>

    <div class="modal-backdrop fade show" v-if="show"></div>
  </div>
</template>

<script>
import VueDraggableResizable from 'vue-draggable-resizable'
export default {
  components: {
    'vue-draggable-resizable': VueDraggableResizable,
  },
  name: 'modal',
  props: {
    title: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 500,
      required: false,
    },
    paddingBottom: {
      type: Number,
      default: 100,
      required: false,
    },

    isDrag: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      show: false,
    }
  },
  methods: {
    /**
     * 팝업 창을 닫는 함수
     *
     * @method onClose
     */
    onClose() {
      
      //이벤트 버스등 하위의 것들을 직접 호출할 수 없어서 상위의 리스트의 method를 직접 호출
      /*
      if(this.$parent.$listeners.onCloseContentDetail){
        this.$parent.$listeners.onCloseContentDetail({ isUpdate: true })
      }
      if(this.$parent.$listeners.onCloseContentDetailDifferent){
        this.$parent.$listeners.onCloseContentDetailDifferent({ isUpdate: true })
      }
      */
      
      this.show = false
      this.$refs.modalBody.scrollTop = 0
    },
    /**
     * 팝업 창을 띄우는 함수
     *
     * @method onShow
     */
    onShow() {
      this.show = true
    },
  },
}
</script>

<style scoped>
.modal-content {
  max-height: 100%;
}

.modal-body {
  max-height: 800px;
  overflow-y: auto;
}
</style>
