/**
 * 월초 날짜 가져오는 함수
 * 
 * @method nowDate
 * @return {String} result 월초 날짜
 */
export const beginningDate = () => {
    let today = new Date()

    let dd = 1
    let mm = today.getMonth() + 1
    let yyyy = today.getFullYear()
    let hour = today.getHours()
    let min = today.getMinutes()
    let sec = today.getSeconds()

    if (dd < 10) dd = '0' + dd
    if (mm < 10) mm = '0' + mm
    if (hour < 10) hour = '0' + hour
    if (min < 10) min = '0' + min
    if (sec < 10) sec = '0' + sec

    return `${yyyy}-${mm}-${dd} ${hour}:${min}:${sec}`
}

/**
 * 오늘 날짜 가져오는 함수
 * 
 * @method nowDate
 * @param {Number} addDay 더 할 일수
 * @return {String} result 오늘 날짜
 */
export const nowDate = (addDay = 0) => {
    let today = new Date()

    let dd = today.getDate() + addDay
    let mm = today.getMonth() + 1
    let yyyy = today.getFullYear()
    let hour = today.getHours()
    let min = today.getMinutes()
    let sec = today.getSeconds()

    if (dd < 10) dd = '0' + dd
    if (mm < 10) mm = '0' + mm
    if (hour < 10) hour = '0' + hour
    if (min < 10) min = '0' + min
    if (sec < 10) sec = '0' + sec

    return `${yyyy}-${mm}-${dd} ${hour}:${min}:${sec}`
}

/**
 * 오늘 날짜 가져오는 함수
 * 
 * @method nowDateYYYYMMDDHHMM
 * @param {Number} addDay 더 할 일수
 * @return {String} result 오늘 날짜
 */
export const nowDateYYYYMMDDHHMM = (addDay = 0) => {
    let today = new Date()

    let dd = today.getDate() + addDay
    let mm = today.getMonth() + 1
    let yyyy = today.getFullYear()
    let hour = today.getHours()
    let min = today.getMinutes()
    let sec = today.getSeconds()

    if (dd < 10) dd = '0' + dd
    if (mm < 10) mm = '0' + mm
    if (hour < 10) hour = '0' + hour
    if (min < 10) min = '0' + min
    if (sec < 10) sec = '0' + sec

    return `${yyyy}-${mm}-${dd} ${hour}:${min}`
}

/**
 * 날짜를 더하는 함수
 * 
 * @method addDate
 * @param {String} currentDate 현재 데이트 
 * @param {Number} prevDay 더 할 일 수
 * @return {String} result 더한 날짜
 */
export const addDate = (currentDate, prevDay = 0) => {
    let resultDate = new Date(Date.parse(currentDate) + prevDay * 1000 * 60 * 60 * 24)

    let dd = resultDate.getDate()
    let mm = resultDate.getMonth() + 1
    let yyyy = resultDate.getFullYear()
    let hour = resultDate.getHours()
    let min = resultDate.getMinutes()
    let sec = resultDate.getSeconds()

    if (dd < 10) dd = '0' + dd
    if (mm < 10) mm = '0' + mm
    if (hour < 10) hour = '0' + hour
    if (min < 10) min = '0' + min
    if (sec < 10) sec = '0' + sec

    return `${yyyy}-${mm}-${dd} ${hour}:${min}:${sec}`
}

/**
 * 날짜를 문자열로 변경하는 함수
 * 
 * @method convertDateToStr
 * @param {Object} date 현재 데이트 
 * @return {String} result 날짜
 */
export const convertDateToStr = (date) => {
    let dd = date.getDate()
    let mm = date.getMonth() + 1
    let yyyy = date.getFullYear()
    let hour = date.getHours()
    let min = date.getMinutes()
    let sec = date.getSeconds()

    if (dd < 10) dd = '0' + dd
    if (mm < 10) mm = '0' + mm
    if (hour < 10) hour = '0' + hour
    if (min < 10) min = '0' + min
    if (sec < 10) sec = '0' + sec

    return `${yyyy}-${mm}-${dd} ${hour}:${min}:${sec}`
}

/**
 * 시작/종료 날짜를 문자열로 변경하는 함수
 * 
 * @method convertSEDateToStr
 * @param {Object} date 현재 데이트 
 * @return {String} result 날짜
 */
export const convertSEDateToStr = (date) => {
    try {
        let dd = date.getDate()
        let mm = date.getMonth() + 1
        let yyyy = date.getFullYear()

        if (dd < 10) dd = '0' + dd
        if (mm < 10) mm = '0' + mm

        /*let hour = '23'
        let min = '59'
        let sec = '59'
        if (isStart) {
          hour = '00'
          min = '00'
          sec = '00'
        }*/

        return `${yyyy}-${mm}-${dd}`
    } catch (e) {
        return date
    }
}

/**
 * 시작/종료 날짜 차이 구하는 함수
 * 
 * @param {String} paramStartDate 시작날짜
 * @param {String} paramEndDate 종료날짜
 * @return {String} result **시간 **분
 */
export const getDiffTimeTxt = (paramStartDate, paramEndDate) => {
    let startDate = Date.parse(paramStartDate)
    let endDate = Date.parse(paramEndDate)

    let diff = (endDate - startDate) / 1000

    let min = 60
    let hour = min * 60
    let day = hour * 60

    let sh = parseInt((diff % day) / hour)
    let sm = parseInt((diff % hour) / min)

    return `${sh}시간 ${sm}분`
}

/**
 * 오늘 날짜 가져오는 함수
 * 
 * @method nowDate
 * @param {Number} addDay 더 할 일수
 * @return {String} result 오늘 날짜
 */
export const nowDateTrim = (addDay = 0) => {
    let today = new Date()

    let dd = today.getDate() + addDay
    let mm = today.getMonth() + 1
    let yyyy = today.getFullYear()
    let hour = today.getHours()
    let min = today.getMinutes()
    let sec = today.getSeconds()

    if (dd < 10) dd = '0' + dd
    if (mm < 10) mm = '0' + mm
    if (hour < 10) hour = '0' + hour
    if (min < 10) min = '0' + min
    if (sec < 10) sec = '0' + sec

    return `${yyyy}-${mm}-${dd} ${hour}:${min}:${sec}`
}

/**
 * Format date to string
 * @param {Date | null} date The date to format
 * @return {string} The formatted date string or an empty string if the date is null
 */
export const formatDateString = (date) => {
    if (!date) return ''; // Check if the date is null

    if (typeof date === 'string') {
        date = new Date(date);
    }

    const dd = date.getDate();
    const mm = date.getMonth() + 1;
    const yyyy = date.getFullYear();
    const hour = date.getHours();
    const min = date.getMinutes();
    const sec = date.getSeconds();

    const pad = (num) => (num < 10 ? '0' + num : num); // Add leading zero if needed

    return `${yyyy}-${pad(mm)}-${pad(dd)} ${pad(hour)}:${pad(min)}:${pad(sec)}`;
};


export const easyFormatDateString = (date) => {
    if (!date) return ''; // Check if the date is null


    const dateParts = date.split(/\s+/);

    if (dateParts[0].length === 10) {
        return dateParts[0]
    } else {
        // 추출한 부분을 올바른 위치에 매핑하여 Date 객체 생성
        const yearOrg = parseInt(dateParts[2], 10);
        const monthOrg = parseInt(dateParts[0], 10) - 1; // JavaScript의 월은 0부터 시작하므로 1을 빼줍니다.
        const dayOrg = parseInt(dateParts[1], 10);
        let hourOrg = parseInt(dateParts[3].split(":")[0], 10);
        const minuteOrg = parseInt(dateParts[3].split(":")[1], 10);

        // AM 또는 PM에 따라 시간을 조정
        const isPM = dateParts[4] === "PM";
        if (isPM && hourOrg < 12) {
            hourOrg += 12;
        }

        const dateObject = new Date(yearOrg, monthOrg, dayOrg, hourOrg, minuteOrg);

        const dd = dateObject.getDate();
        const mm = dateObject.getMonth() + 1;
        const yyyy = dateObject.getFullYear();
        const hour = dateObject.getHours();
        const min = dateObject.getMinutes();
        const sec = dateObject.getSeconds();

        const pad = (num) => (num < 10 ? '0' + num : num); // Add leading zero if needed

        //return `${yyyy}-${pad(mm)}-${pad(dd)} ${pad(hour)}:${pad(min)}:${pad(sec)}`;
        return `${yyyy}-${pad(mm)}-${pad(dd)}`;
    }
};

/**
 * 날짜를 년, 월, 일로 분리하는 함수
 * 
 * @method separateDate
 * @param {String} dateString 날짜 문자열 (예: '2023-11-11')
 * @param {String} type 분리할 요소 타입 ('year', 'month', 'day' 중 하나)
 * @return {Number} 분리된 날짜 정보
 */
export const separateDate = (dateString, type) => {
    try {
        const dateObject = new Date(dateString);

        switch (type) {
            case 'year':
                return dateObject.getFullYear();
            case 'month':
                return dateObject.getMonth() + 1; // 월은 0부터 시작하므로 1을 더해줍니다.
            case 'day':
                return dateObject.getDate();
            default:
                console.error('유효하지 않은 타입입니다.');
                return null;
        }
    } catch (e) {
        console.error('날짜 분리 중 오류가 발생했습니다:', e);
        return null;
    }
};

/**
 * 배열을 날짜 형식의 문자열로 변환하는 함수
 * 
 * @param {Array} dateArray 날짜 정보를 담고 있는 배열 [년, 월, 일, 시, 분, 초, 밀리초]
 * @return {String} result 변환된 날짜 문자열 (예: '2024-01-07 02:06:35')
 */
export const formatDateFromArray = (dateArray) => {
    if (!dateArray) {
        return;
    }
    const [year, month, day, hours, minutes, seconds] = dateArray;
    const formattedDate = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')} ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    return formattedDate;
};

export const formatDateYmd = (dateString) => {
    let dateObject;
    let formattedDate;

    if(!dateString || dateString === '1900-01-01T00:00'){
        return ''
    }

    if (Array.isArray(dateString) && dateString.length >= 6) {
        // dateString이 배열인 경우
        let [year, month, day, hour, minute, second, millisecond] = dateString;
        // 날짜를 원하는 형식으로 포맷팅
        formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')} ${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')}`;
    } else if (dateString && (dateString.indexOf('AM') !== -1 || dateString.indexOf('PM') !== -1)) {
        // "02 22 2022 12:00AM" 형식인 경우
        const [month, day, year, time] = dateString.split(' ');
        const [hour, minute, ampm] = time.match(/(\d+):(\d+)([APMapm]{2})/).slice(1);
        const ampmHour = ampm === 'AM' ? hour : parseInt(hour) + 12
        dateObject = new Date(`${year}-${month}-${day}T${ampmHour}:${minute}`);
        // 날짜를 원하는 형식으로 포맷팅
        formattedDate = `${dateObject.getFullYear()}-${(dateObject.getMonth() + 1).toString().padStart(2, '0')}-${dateObject.getDate().toString().padStart(2, '0')} ${dateObject.getHours().toString().padStart(2, '0')}:${dateObject.getMinutes().toString().padStart(2, '0')}:${dateObject.getSeconds().toString().padStart(2, '0')}`;
    } else {
        // "2022-03-19T00:00" 형식인 경우
        dateObject = new Date(dateString);
        // 날짜를 원하는 형식으로 포맷팅
        formattedDate = `${dateObject.getFullYear()}-${(dateObject.getMonth() + 1).toString().padStart(2, '0')}-${dateObject.getDate().toString().padStart(2, '0')} ${dateObject.getHours().toString().padStart(2, '0')}:${dateObject.getMinutes().toString().padStart(2, '0')}:${dateObject.getSeconds().toString().padStart(2, '0')}`;
    }

    // 포맷팅된 날짜 반환
    return formattedDate;
};

export const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // 월은 0부터 시작하므로 1을 더하고 두 자리로 만듭니다.
    const day = String(date.getDate()).padStart(2, "0"); // 일자를 두 자리로 만듭니다.
    return `${year}-${month}-${day}`;
}

export const formatDateKor = (date1) => {
    const date   = new Date(date1)
    const year   = date.getFullYear();
    const month  = String(date.getMonth() + 1).padStart(2, "0"); // 월은 0부터 시작하므로 1을 더하고 두 자리로 만듭니다.
    const day    = String(date.getDate()).padStart(2, "0"); // 일자를 두 자리로 만듭니다.
    const hour   = String(date.getHours()).padStart(2, "0")
    const minute = String(date.getMinutes()).padStart(2, "0")

    return `${year}년 ${month}월 ${day}일 ${minute}시 ${hour}분`;
}