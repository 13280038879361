<!-- 상세보기모달 -->
<template>
    <div>
        <modal ref="modal" :title="'부가서비스 보기'" :width="600">
            <template v-slot:body>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">서비스 타입</label>
                            <div class="col-sm-8">
                                <span class="form-control">{{ serviceTypeList[userData.serviceType] }}</span>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">부가서비스명</label>
                            <div class="col-sm-8">
                                <span class="form-control">{{ userData.name }}</span>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">가격</label>
                            <div class="col-sm-8">
                                <span class="form-control">{{ userData.unitPrice }}</span>
                            </div>
                        </div>
					</div>
                </div>
            </template>
            <template v-slot:footer>
                <button type="button" class="btn btn-secondary" @click="onClickClose">닫기</button>
            </template>
        </modal>

    </div>
</template>

<script>
import Modal from '../Modal'
import { mapState } from 'vuex'

export default {
    components: {
        Modal,
    },
    data() {
        return {
            isView          : false,
            userInfo        : {},
            userData        : {},
            serviceTypeList : {},
            seq             : 0
        }
    },
    created() {
        const userInfo = this.$store.getters.getUserInfo()
        if (_.isEmpty(userInfo)) {
            this.$router.replace({
                path: '/'
            })
        }
        this.userInfo = userInfo
        this.serviceTypeList = this.$store.getters.getServiceType
    },
    computed: {
        ...mapState(['domainUrl']),
        ...mapState(['imageUrl'])
    },
    methods: {
        onShow(payload) {
            this.userData = {}
            this.seq  = 0

			if(payload && payload.seq){
                this.seq = payload.seq
                this.getTMData()
			}
            this.$refs.modal.onShow()
        },

        /**
         * 데이터를 가져오는 함수
         *
         * @method getShopSetting
         * @param {boolean} isSearchBtnClick 검색 버튼 눌렀는지 여부
         */
         getTMData() {
            this.$connect(
				`/api/additionalService/${this.seq}`,
                'GET'
            )
            .then(bodyData => {
                this.userData = bodyData.list
            })
            .catch(error => {
                console.log("error", error);
            })
        },

        /**
         * 모달닫기
         *
         * @method onClickClose
         */
        onClickClose() {
            this.$refs.modal.onClose()
        },
    },
}
</script>