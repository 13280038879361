/**
 * 숫자를 천 단위로 구분하여 포맷팅하는 함수
 * 
 * @method numberFormat
 * @param {Number} num 포맷팅할 숫자
 * @return {String} result 천 단위로 구분된 문자열
 */
export const numberFormat = (num) => {
    // num이 null 또는 undefined인 경우 빈 문자열 반환
    if (num === null || num === undefined) {
        return '';
    }

    // 천 단위로 구분하는 정규식
    var regexp = /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g

    // 숫자를 문자열로 변환하고 천 단위로 구분하여 반환
    return Math.ceil(num).toString().replace(regexp, ',');
    //return num.toLocaleString('ko-KR')
}