<template>
    <div class="layout-login">
        <div class="login-wrap">
            <div class="login-content" style="width: auto; top: 100px; transform: translateX(-50%);">
                <div id="methodBank">
					<h2>은행별 빠른계좌조회 등록 방법</h2>
					<ul class="lnb">
						<li v-for="(bank, index) in bankList" :key="index" :class="{ now: activeBank === bank.id }">
							<a href="javascript:void(0)" @click="activeBank = bank.id">{{ bank.name }}</a>
						</li>
					</ul>
					<div class="txt">
						<ul>
							<li>- 은행명 클릭시 빠른계좌 등록하실 수 있는 사이트로 연결됩니다.</li>
							<li>- 인터넷뱅킹에 가입하셔야만 비뱅크 서비스 이용이 가능합니다. (은행별 필요서류 또는 방문여부가 상이할 수 있습니다.)</li>
							<li>- 은행별 빠른계좌 명칭이 상이하오니 참고하여 이용하시기 바랍니다.</li>
						</ul>
					</div>
					<div class="inf">
						<div v-for="(bank, index) in bankList" :key="index" v-show="activeBank === bank.id" class="bank-w">
							<p class="tit">
								<span>▶ {{ bank.name }} 빠른계좌 등록</span>
								<a :href="bank.link" target="_blank">[바로가기]</a>
							</p>
							<dl class="dsc" v-html="bank.description"></dl>
						</div>
					</div>
				</div>
            </div>
        </div>
        <div class="login-footer" style="position: absolute; bottom:0; left:50%; transform:translate(-50%,-50%); color: #FFF;">
            <div class="footer-copy">© 골드오더</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'accountBankHelper',
	data() {
        return {
			activeBank: 'bankKb', // Default active tab
            bankList: [
                {
                    id: 'bankKb',
                    name: '국민은행',
                    link: 'https://obank.kbstar.com/quics?page=C025255&amp;cc=b028364:b028702&amp;QSL=F',
                    description: `
                        <dt>국민은행 (www.kbstar.com) 접속</dt>
                        <dd>[빠른계좌] 클릭 &gt; 빠른조회 등록하기 클릭</dd>
                    `
                },
                {
                    id: 'bankWoori',
                    name: '우리은행',
                    link: 'https://spib.wooribank.com/spd/Dream?withyou=CMLGN0010',
                    description: `
                        <dt>우리은행 (www.wooribank.com) 접속</dt>
                        <dd>[스피드 계좌조회] 클릭 &gt; [스피드조회계좌 등록/해제]탭 클릭 &gt; 로그인 &gt; 스피드 계좌조회 등록</dd>
                    `
                },
                {
                    id: 'bankHana',
                    name: '하나은행',
                    link: 'http://www.hanabank.com',
                    description: `
                        <dt>하나은행 (www.hanabank.com) 접속</dt>
                        <dd>개인인터넷뱅킹[My Hana &gt; 뱅킹정보관리 &gt; 계좌정보관리 &gt; 빠른계좌조회관리]</dd>
                        <dd>기업인터넷뱅킹[사용자관리 &gt; 계좌관리 &gt; 빠른계좌조회설정]</dd>
                    `
                },
                {
                    id: 'bankNh',
                    name: '농협은행',
                    link: 'https://banking.nonghyup.com/nhbank.html',
                    description: `
                        <dt>NH농협은행 (banking.nonghyup.com) 접속</dt>
                        <dd>뱅킹서비스 &gt; 간편조회/이체 클릭 &gt; [간편조회/이체] 클릭</dd>
                    `
                },
                {
                    id: 'bankIbk',
                    name: '기업은행',
                    link: 'http://mybank.ibk.co.kr/ibs/jsp/guest/qcs/qcs10/qcs1010/PQCS101000_i.jsp?q=C0A8822C189E02D9A72DC1A738E2C160D0A42709C2C6DB;xiziBIBe0A2ZTBqz/rUfQDEsdOG63CoZmiNL9hAZFM2pwETI6a63tmCeCO80DBonsgq/UNrXWfWiRTj5EPtWq75lmakznwW40Q608JAqAJPreVxTW79jtXeksEIdHbnR;PKbifalEZf2EZBC23FJs/C4MbBQ%3D',
                    description: `
                        <dt>기업은행 (www.ibk.co.kr) 접속</dt>
                        <dd>개인뱅킹or기업뱅킹 클릭 &gt; [계좌조회] 클릭 &gt; [빠른서비스신청] 클릭</dd>
                    `
                },
                {
                    id: 'bankPost',
                    name: '우체국 예금',
                    link: 'http://www.epostbank.go.kr',
                    description: `
                        <dt>우체국 예금보험 (www.epostbank.go.kr) 접속</dt>
                        <dd>[간편조회하기] 클릭 &gt; 개인 간편조회 계좌등록 or 기업 간편조회 계좌등록 클릭</dd>
                    `
                },
                {
                    id: 'bankSh',
                    name: '신한은행',
                    link: 'http://easy.shinhan.com/sandbox/index.jsp',
                    description: `
                        <dt>신한은행 (www.shinhan.com) 접속</dt>
                        <dd>[간편서비스] 클릭 &gt; 로그인 &gt; 간편계좌관리 탭 클릭 &gt; [간편조회계좌 추가하기] 클릭</dd>
                    `
                },
                {
                    id: 'bankKeb',
                    name: '외환은행',
                    link: 'https://open.keb.co.kr/OPSPAC010001.web',
                    description: `
                        <dt>외환은행 (www.keb.co.kr) 접속</dt>
                        <dd>개인뱅킹 로그인페이지 접속 &gt; 개인뱅킹 마우스오버 &gt; 뱅킹정보관리 마우스오버 &gt; 이용고객등록 클릭 &gt; [간편조회고객등록] 클릭</dd>
                    `
                }
            ]
        }
    },
}
</script>

<style scoped>
@import '../../assets/css/login.css';
@import '../../assets/css/bootstrap.css';
@import '../../assets/css/style.css';

.login-wrap {
	background: none;
}
.footer-copy {
	color: #444;
}

/* 은행 */
#methodBank { width: 604px; }
#methodBank .lnb { z-index: 1; zoom: 1; margin-top: 20px; }
#methodBank .lnb:after { content: ""; clear: both; display: block; }
#methodBank .lnb li { float: left; width: 25%; height: 41px; border: 1px solid #d8d8d8; position: relative; margin-right: -1px; margin-bottom: -1px; background-repeat: no-repeat; background-position: 50% 50%; }
#methodBank .lnb li a { display: block; width: 100%; height: 100%; color: #000; line-height: 40px; }
#methodBank .lnb li.now { z-index: 2; border: 1px solid #0046c8; background-color: #f5f9fa; }
#methodBank .txt  { margin-top:10px; }
#methodBank .txt ul li { padding: 5px 0 0 5px; color: #808080; font-size: 11px; text-align: left; }
#methodBank .inf { margin-top: 20px; padding: 20px 38px; border: 1px solid #aac0d5; background-color: #f5f9fa; }
#methodBank .inf .tit { color: #0047c8; font-size: 14px; font-weight: bold; text-align: left; }
#methodBank .inf .tit span {float:left; }
#methodBank .inf .tit a { font-size:13px; margin-left:10px; padding-top:0px; display:block; float:left; color:red; }
#methodBank .inf .tit a:hover { text-decoration:underline;}
#methodBank .inf .dsc { padding-top: 6px; clear:both; text-align: left; }
#methodBank .inf .dsc dt { margin-top: 6px; font-weight: bold; }
#methodBank .inf .dsc dd { margin-top: 6px; padding-left: 12px; }	/*background: url(./img/common/bul_arr_h9.gif) no-repeat 0 4px;*/
#methodBank .inf .dsc p.sub { margin-top: 6px; }
</style>