<!-- 상세보기모달 -->
<template>
    <div>
        <modal ref="modal" :title="'사용자 보기'" :width="1200">
            <template v-slot:body>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">서비스</label>
                            <div class="col-sm-4">
                                <span class="form-control">{{ serviceTypeList[userData.serviceType] }}</span>
                            </div>
                            <label class="col-sm-2 col-form-label">권한</label>
                            <div class="col-sm-4">
                                <span class="form-control">{{ roleList[userData.role] }}</span>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">사용 시작일</label>
                            <div class="col-sm-4">
                                <span class="form-control">{{ userData.serviceStartDatetime }}</span>
                            </div>
                            <label class="col-sm-2 col-form-label">사용 종료일</label>
                            <div class="col-sm-4">
                                <span class="form-control">{{ userData.serviceEndDatetime }}</span>
                            </div>
                        </div>

                        <div class="form-group row">
                                <label class="col-sm-2 col-form-label">부가서비스</label>
                                <div class="col-sm-10">
                                    <template v-for="(item, index) in userData.memberAdditionalService">
                                        <div class="form-group row no-mrg-horizon" :key="index">
                                            <div class="col-sm-4 no-pdd-horizon">
                                                <span class="form-control">{{ item.name }}</span>
                                            </div>
                                            <div class="col-sm-4 no-pdd-horizon">
                                                <span class="form-control text-center">{{ onNumberFormat(item.unitPrice) }}원</span>
                                            </div>
                                            <div class="col-sm-4 no-pdd-horizon">
                                                <span class="form-control text-center">{{ onFormatDateYmd(item.createDatetime) }}</span>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>

                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">아이디</label>
                            <div class="col-sm-4">
                                <span class="form-control">{{ userData.id }}</span>
                            </div>
                            <label class="col-sm-2 col-form-label">바로빌 아이디</label>
                            <div class="col-sm-4">
                                <span class="form-control">{{ userData.memberBarobill && userData.memberBarobill.barobillId?userData.memberBarobill.barobillId:'미가입' }}</span>
                            </div>
                        </div>
						<div class="form-group row">
                            <label class="col-sm-2 col-form-label">상호명</label>
                            <div class="col-sm-4">
                                <span class="form-control">{{ userData.name }}</span>
                            </div>
                            <label class="col-sm-2 col-form-label">대표자명</label>
                            <div class="col-sm-4">
                                <span class="form-control">{{ userData.ceoName }}</span>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">연락처</label>
                            <div class="col-sm-4">
                                <span class="form-control">{{ userData.phone }}</span>
                            </div>
                            <label class="col-sm-2 col-form-label">이메일</label>
                            <div class="col-sm-4">
                                <span class="form-control">{{ userData.email }}</span>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">로고</label>
                            <div class="col-sm-10">
                                <div v-if="userData.logo" style="margin-bottom: 5px;"><img :src="userData.logo"  style="height: 50px;"/></div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">계좌정보</label>
                            <div class="col-sm-10">
                                <template v-for="(item, index) in memberAccount">
                                    <div class="form-group row" style="position: relative; margin-left: 0;" :key="index">
                                        <label class="col-sm-1 col-form-label">은행명</label>
                                        <div class="col-sm-2">
                                            <span class="form-control">{{ item.bankName }}</span>
                                        </div>
                                        <label class="col-sm-1 col-form-label">예금주</label>
                                        <div class="col-sm-3">
                                            <span class="form-control">{{ item.accountName }}</span>
                                        </div>
                                        <label class="col-sm-1 col-form-label">계좌번호</label>
                                        <div class="col-sm-2">
                                            <span class="form-control">{{ item.account }}</span>
                                        </div>
                                        <div class="col-sm-1">
                                            <span class="form-control">{{ item.accountType === 'P' ? '개인' : (item.accountType === 'C'?'법인':'') }}</span>
                                        </div>
                                        <div class="col-sm-1">
                                            <span class="form-control" style="font-size: 11px; padding-left: 2px; padding-right: 0; color: blue;">{{ getBarobillRegistAccountState(item) }}</span>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <!--
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">은행명</label>
                            <div class="col-sm-4">
                                <input type="text" class="form-control" placeholder="은행명을 입력하세요." v-model="userData.bankName" />
                            </div>
                            <label class="col-sm-2 col-form-label">예금주</label>
                            <div class="col-sm-4">
                                <input type="text" class="form-control" placeholder="예금주를 입력하세요." v-model="userData.accountName" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">계좌번호</label>
                            <div class="col-sm-4">
                                <input type="text" class="form-control" placeholder="계좌번호를 입력하세요." v-model="userData.account" />
                            </div>
                        </div>
                        -->
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">사업자등록번호</label>
                            <div class="col-sm-4">
                                <span class="form-control">{{ userData.businessNumber }}</span>
                            </div>
                            <label class="col-sm-2 col-form-label">통신판매업신고번호</label>
                            <div class="col-sm-4">
                                <span class="form-control">{{ userData.mailOrderSalesRegistrationNumber }}</span>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">업태</label>
                            <div class="col-sm-4">
                                <span class="form-control">{{ userData.businessType }}</span>
                            </div>
                            <label class="col-sm-2 col-form-label">업종</label>
                            <div class="col-sm-4">
                                <span class="form-control">{{ userData.businessItem }}</span>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">사업장 주소</label>
                            <div class="col-sm-10">
                                <span class="form-control">{{ userData.address }}</span><br>
                                <span class="form-control">{{ userData.addressDetail }}</span>
                            </div>
                        </div>
					</div>
                </div>
            </template>
            <template v-slot:footer>
                <button type="button" class="btn btn-secondary" @click="onClickClose">닫기</button>
            </template>
        </modal>

    </div>
</template>

<script>
import Modal from '../Modal'
import { mapState } from 'vuex'
import * as DateUtils from '../../utils/date-utils'
import * as EtcUtils from '../../utils/etc-utils'

export default {
    components: {
        Modal,
    },
    data() {
        return {
            isView                    : false,
            userInfo                  : {},
            userData                  : {},
            memberAccount             : [],
            isPasswordChange          : false,
            serviceTypeList           : {},
            roleList                  : {},
            seq                       : 0,
            barobillRegistAccountData : []
        }
    },
    created() {
        const userInfo = this.$store.getters.getUserInfo()
        if (_.isEmpty(userInfo)) {
            this.$router.replace({
                path: '/'
            })
        }
        this.userInfo = userInfo
        this.serviceTypeList = this.$store.getters.getServiceType
        this.roleList = this.$store.getters.getRole
    },
    computed: {
        ...mapState(['domainUrl']),
        ...mapState(['imageUrl'])
    },
    methods: {
        onShow(payload) {
            this.userData = {}
            this.memberAccount = []
            this.seq  = 0
            this.barobillRegistAccountData = []

			if(payload && payload.seq){
                this.seq = payload.seq
                this.getTMData()
			}
            this.$refs.modal.onShow()
        },

        /**
         * 데이터를 가져오는 함수
         *
         * @method getShopSetting
         * @param {boolean} isSearchBtnClick 검색 버튼 눌렀는지 여부
         */
         getTMData() {
            this.$connect(
				`/api/member/${this.seq}`,
                'GET'
            )
            .then(bodyData => {
                this.userData = bodyData.list
                this.memberAccount = JSON.parse(JSON.stringify(this.userData.memberAccount))
                this.getBarobillRegistAccount()
            })
            .catch(error => {
                console.log("error", error);
            })
        },

        /**
         * 모달닫기
         *
         * @method onClickClose
         */
        onClickClose() {
            this.$refs.modal.onClose()
        },

        /**
         * 바로빌과 연동해 사용하는 은행코드
         *
         * @method getBankCodeList
         */
         getBarobillRegistAccount(){
            this.$connect(
				`/api/member/getBarobillRegistAccount/${this.userData.id}`,
                'GET'
            )
            .then(bodyData => {
                this.barobillRegistAccountData = bodyData.list
            })
            .catch(error => {
                console.log("error", error);
            })
        },

        getBarobillRegistAccountState(item){
            const accountData = this.barobillRegistAccountData.find(
                data => data.account === item.account
            );
            if (accountData && accountData.isRegist) {
                return accountData.isRegist === 'R' ? '입금 연동중' : '';
            }
            return '-';
        },

        /**
         * 숫자를 천 단위로 구분하여 포맷팅
         *
         * @method onNumberFormat
         * @param {Number} num 포맷팅할 숫자
         * @return {String} result 천 단위로 구분된 문자열
         */
         onNumberFormat(num) {
            return num?EtcUtils.numberFormat(num):0
        },

        /**
         * 주어진 ISO 형식의 날짜 문자열을 "YYYY-MM-DD HH:mm:ss" 형식으로 변환하는 메서드
         *
         * @method onFormatDateYmd
         * @param {string} value 날짜 문자열
         * @returns {string} 포맷팅된 날짜 문자열
         */
        onFormatDateYmd(dateString) {
            return DateUtils.formatDateYmd(dateString).substring(0, 10)
        },
    },
}
</script>