const menus = [
  {
    seq: 0,
    pageTitle: '대시보드',
    routePath: '/main/dashboard',
    isReady: false, // 준비 중 얼럿을 띄울지 유무(true 띄움, false 띄우지 않음)
    menus: []
  }, // 대쉬보드
  {
    seq: 1,
    pageTitle: '대시보드',
    routePath: '/main/dashboard',
    isReady: false,
    menus: []
  }, // 공지사항
  {
    seq: 2,
    title: '사용자 관리',
    isReady: false,
    menus: [
      {
        seq: 0,
        icon: 'topnav_icon_cs',
        title: '사용자 관리',
        menu: {
          title: '사용자 관리',
          menus: [
            {
              seq: 0,
              pageTitle: '사용자 목록',
              routePath: '/main/memberList'
            },
          ]
        }
      },
      {
        seq: 1,
        icon: 'topnav_icon_cs',
        title: '문자 관리',
        menu: {
          title: '문자 관리',
          menus: [
            {
              seq: 0,
              pageTitle: '문자전송내역',
              routePath: '/main/memberSendMessageList'
            },
            {
              seq: 1,
              pageTitle: '문자내용관리',
              routePath: '/main/messageList'
            },
          ]
        }
      },
      {
        seq: 2,
        icon: 'topnav_icon_cs',
        title: '서비스 관리',
        menu: {
          title: '서비스 관리',
          menus: [
            {
              seq: 0,
              pageTitle: '부가서비스',
              routePath: '/main/additionalServiceList'
            },
          ]
        }
      }
    ]
  }
]

export { menus }