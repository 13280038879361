<template>
    <div>
        <div class="boxwrap">
            <div class="searchwrap">
                <form>
                    <div class="form-row align-items-center">
                        <label class="col-form-label">검색어</label>
                        <select-box
                            ref="selectBox"
                            :selectBoxs="[
                                { name: '주문상태', value: 'STATUS' },
                                { name: '문자형태', value: 'TYPE' },           
                                { name: '문자내용', value: `CONTENT` }
                            ]"
                            :default-select-box-value="searchType"
                            @onChangeSearchBox="setSelectBox"
                            :key="viewKey + 1"
                        ></select-box>
                        <div class="col-auto ssb-search w-25">
                            <template v-if="searchType === 'STATUS'">
                                <select v-model="searchText" class="form-control">
                                    <template v-for="(item, index) in statusList">
									<option :value="item.status" :key="index">{{ item.status }}</option>
                                    </template>
                                </select>
                            </template>
                            <template v-else-if="searchType === 'TYPE'">
                                <select v-model="searchText" class="form-control">
                                    <option value="SMS">SMS</option>
                                    <option value="LMS">LMS</option>
                                </select>
                            </template>
                            <template v-else>
                                <input v-model="searchText" class="form-control" type="text" placeholder="검색어를 입력하세요" />
                            </template>
                        </div>
                    </div>
                    <div class="form-row align-items-center">
                        <label class="col-form-label">상점 아이디</label>
						<div class="col-auto ssb-search w-25">
                        	<input v-model="searchOwnerId" class="form-control" type="text" placeholder="상점 아이디를 입력하세요" />
						</div>
                    </div>
                    <div class="form-row">
                        <div class="col-12 text-center">
                        <button type="button" class="btn btn-primary btn-search" @click="onClickSearch">검색</button>&nbsp;
                        <button type="button" class="btn btn-secondary btn-search" @click="onClickClear">전체검색</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="title">
                <div class="option-btn mrg-left-10">
					<select class="form-control" v-model="tableListRow" @change="onChangeListRow">
                        <option value="10">10개씩 보기</option>
						<option value="20">20개씩 보기</option>
						<option value="40">40개씩 보기</option>
						<option value="80">80개씩 보기</option>
						<option value="100">100개씩 보기</option>
					</select>
				</div>
                <div class="option-btn mrg-left-10">
                    <a href="javascript:void(0)" class="btn btn-primary btn-sm" @click="onClickModalMessageSave">내용 등록</a>
                </div>
                <h3 class="text-left">목록 (총
                    <strong class="point">{{ totalCount }}</strong>건)
                </h3>
            </div>
            <table class="table table-bordered">
                <thead class="thead-contact">
                    <tr>
                        <th scope="col">일련번호</th>
                        <th scope="col">상점 아이디</th>
                        <th scope="col">문자형태</th>
                        <th scope="col">주문상태</th>
                        <th scope="col">문자제목</th>
                        <th scope="col">문자내용</th>
                        <th scope="col">관리</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(item, index) in tableList">
                        <tr :key="index" class="link">
                            <td @click="onClickModalMessageDetail(item)">{{ item.seq }}</td>
                            <td @click="onClickModalMessageDetail(item)">{{ item.ownerId }}</td>
                            <td @click="onClickModalMessageDetail(item)">{{ item.type }}</td>
                            <td @click="onClickModalMessageDetail(item)">{{ item.status }}</td>
                            <td @click="onClickModalMessageDetail(item)" class="text-left">{{ item.subject }}</td>
                            <td @click="onClickModalMessageDetail(item)" class="text-left">{{ item.content }}</td>
                            <td><button type="button" class="btn btn-secondary btn-xs" @click="onClickModalMessageSave(item)">수정</button> <button type="button" class="btn btn-secondary btn-xs" @click="onClickModalMessageDelete(item)">삭제</button></td>
                        </tr>
                    </template>
                </tbody>
            </table>
            <paging-nations 
			ref="pagingNations" 
			:total-content-count="totalCount" 
			:row-per-page=parseInt(tableListRow)
			v-on:onClickPage="getTMPageNum" 
			:key="pagingViewKey" 
			v-show="totalCount !== 0"
			></paging-nations>
        </div>

		<!-- 상세보기 -->
        <modal-message-detail 
            ref="modalMessageDetail" 
            @onCloseMessageDetail="onCloseMessageDetail"
		></modal-message-detail>

        <!--등록/수정-->
		<modal-message-save 
            ref="modalMessageSave" 
            @onCloseMessageSave="onCloseMessageSave"
		></modal-message-save>

        <!--삭제-->
		<modal-message-delete 
            ref="modalMessageDelete" 
            @onCloseMessageDelete="onCloseMessageDelete"
		></modal-message-delete>

    </div>
</template>
  
<script>
import { tableMixin } from '../../table-mixin'
import * as DateUtils from '../../utils/date-utils'
import * as EtcUtils from '../../utils/etc-utils'
import { mapState } from 'vuex'
import SelectBox from '../../components/SelectBox'

import Pagingnations from '../../components/Pagingnations'
import MMessageDetail from '../../modal/Member/MMessageDetail'
import MMessageSave from '../../modal/Member/MMessageSave'
import MMessageDelete from '../../modal/Member/MMessageDelete'

export default {
    name: 'messageList',
    mixins: [tableMixin],
    components: {
        'select-box'             : SelectBox,
        'paging-nations'         : Pagingnations,
		'modal-message-detail'   : MMessageDetail,
        'modal-message-save'     : MMessageSave,
        'modal-message-delete'   : MMessageDelete,
    },
    data() {
        return {
            searchType   : 'STATUS',
            searchText   : '',
            searchOwnerId: '',
            totalCount   : 0,
            tableList    : [],
            tableListRow : 10,
            userInfo     : {},
            amountSum    : 0,
            statusList   : []
        }
    },
    created() {
        const userInfo = this.$store.getters.getUserInfo()
        if (_.isEmpty(userInfo)) {
            this.$router.replace({
                path: '/'
            })
        }
        this.userInfo = userInfo
    },
    mounted() {
        //목록 LOAD
        this.lmsUseYNCheck()
    },
    computed: {
        ...mapState(['domainUrl'])
    },
    methods: {
        lmsUseYNCheck(){
            this.getTMData();
            this.getStatus()
        },
        /**
         * 데이터를 가져오는 함수
         *
         * @method getTMData
         * @param {boolean} isSearchBtnClick 검색 버튼 눌렀는지 여부
         */
        async getTMData() {
            this.tableList = [];
            this.totalCount = 0;

            const param = {
                "startDate"  : "",
                "endDate"    : "",
                "searchType" : this.searchText?this.searchType:"",
                "searchText" : this.searchText,
                "ownerId"    : this.searchOwnerId
            }
            try {
                const bodyData = await this.$connect(`/api/message/list/${this.searchData.currentPage}/${this.tableListRow}`, 'POST', param);
                this.tableList = bodyData.list.content;
                //console.log(this.tableList)
                this.totalCount = bodyData.totalCount;
            } catch (error) {
                console.log("error", error);
            }
        },

		/**
         * 상세보기 모달 함수
         *
         * @method onmodalMessageDetail
         * @param {Object} item 선택한 아이템 정보
         */
		 onClickModalMessageDetail(item) {
            this.$refs.modalMessageDetail.onShow(item)
        },

        /**
         * 상세보기 모달이 닫힐 때 실행되는 함수
         *
         * @method onCloseMessageDetail
         * @param {Object} payload 상세보기 모달에서 전달된 데이터
         */
        onCloseMessageDetail(payload) {
            if (payload.isUpdate) {
                this.getTMData()
            }
        },

		/**
         * 상세보기 모달 함수
         *
         * @method onClickModalMessageSave
         * @param {Object} item 선택한 아이템 정보
         */
		 onClickModalMessageSave(item) {
            this.$refs.modalMessageSave.onShow(item)
        },

        /**
         * 상세보기 모달이 닫힐 때 실행되는 함수
         *
         * @method onCloseMessageDetail
         * @param {Object} payload 상세보기 모달에서 전달된 데이터
         */
        onCloseMessageSave(payload) {
            if (payload.isUpdate) {
                this.getTMData()
            }
        },

        /**
         * 상태 변경 함수
         *
         * @method onChangeSalesState
         * @param {Object} item 선택한 아이템 정보
         */
		onChangeSalesState(item){

		},

        /**
         * 삭제 모달 함수
         *
         * @method onClickModalMessageDelete
         * @param {Object} item 선택한 아이템 정보
         */
        onClickModalMessageDelete(item){
            this.$refs.modalMessageDelete.onShow(item)
        },

        /**
         * 삭제 모달이 닫힐 때 실행되는 함수
         *
         * @method onCloseMessageSave
         * @param {Object} payload 상세보기 모달에서 전달된 데이터
         */
         onCloseMessageDelete(payload) {
            if (payload.isUpdate) {
                this.getTMData()
            }
        },

        /**
         * 목록 보여지는 ROW 수 변경 함수
         *
         * @method onChangeListRow
         * @param 
         */
         onChangeListRow(){
            this.getTMData()
        },

        /**
         * 숫자를 천 단위로 구분하여 포맷팅
         *
         * @method onNumberFormat
         * @param {Number} num 포맷팅할 숫자
         * @return {String} result 천 단위로 구분된 문자열
         */
         onNumberFormat(num) {
            return num?EtcUtils.numberFormat(num):0
        },

        /**
         * 주어진 ISO 형식의 날짜 문자열을 "YYYY-MM-DD HH:mm:ss" 형식으로 변환하는 메서드
         *
         * @method onFormatDateYmd
         * @param {string} value 날짜 문자열
         * @returns {string} 포맷팅된 날짜 문자열
         */
         onFormatDateYmd(dateString) {
            return DateUtils.formatDateYmd(dateString)
        },

        /**
         * 셀렉트 박스 값을 설정하는 함수
         *
         * @method setSelectBox
         * @param {Object} payload 셀렉트 박스 관련 데이터를 담은 객체
         */
         setSelectBox(payload){
            this.searchType  = payload.selectValue
            this.searchText = ''
        },

        /**
         * 검색 함수
         *
         * @method onClickSearch
         */
         onClickSearch() {
            this.getTMData();
            this.$refs.pagingNations.currentPage = 1;
        },

        /**
         * 초기화(전체검색) 함수
         *
         * @method onClickClear
         */
        onClickClear() {
            this.searchType = 'STATUS'
            this.searchText = ''
            this.searchOwnerId = ''
            this.$refs.selectBox.setSelectedValue(this.searchType);

            this.totalCount = 0
            this.tableList = []
            this.getTMData()
        },

        getStatus(){
            this.$connect(
                `/api/orderStatus/listAll`,
                'GET'
            )
            .then(bodyData => {
                this.statusList = bodyData.list
            })
        }

    }
}
</script>
<style scoped>
.inner-table {
    border: none;
    height: 100%;
}
.inner-table td {
    border-top: none;
    border-bottom: none;
    padding-top: 0;
    padding-bottom: 0;
    height: 32px;
}
.inner-table td:first-child {
    border-left: none;
}
.inner-table td:last-child {
    border-right: none;
}
.inner-table tr:nth-child(n+2) td {
    border-top: 1px dashed #dee2e6;
}
</style>