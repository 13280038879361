<!-- 상세보기모달 -->
<template>
    <div>
        <modal ref="modal" title="내 정보" :width="1400">
            <template v-slot:body>
                <div>
                    <div class="boxwrap">
                        <div class="title">
                            <h3 class="text-left">이용정보</h3>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label">이용중인 서비스</label>
                                    <div class="col-sm-4">
                                        <span class="form-control">{{ userData.serviceType }}</span>
                                    </div>
                                    <label class="col-sm-2 col-form-label">서비스 이용일</label>
                                    <div class="col-sm-4">
                                        <span class="form-control">{{ onFormatDateYmd(userData.serviceStartDatetime) }} ~ {{ onFormatDateYmd(userData.serviceEndDatetime) }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="boxwrap">
                        <div class="title">
                            <h3 class="text-left">상점정보</h3>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label">상호명</label>
                                    <div class="col-sm-4">
                                        <input type="text" class="form-control" placeholder="상호명을 입력하세요." v-model="userData.name" />
                                    </div>
                                    <label class="col-sm-2 col-form-label">로고</label>
                                    <div class="col-sm-4">
                                        <input
                                        type="file"
                                        ref="logoFile"
                                        />
                                        <button type="button" @click="onClickLogoFileUpload">로고 저장</button>
                                    </div>
                                </div>
                                <!--
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label">은행명</label>
                                    <div class="col-sm-4">
                                        <input type="text" class="form-control" placeholder="은행명을 입력하세요." v-model="userData.bankName" />
                                    </div>
                                    <label class="col-sm-2 col-form-label">예금주</label>
                                    <div class="col-sm-4">
                                        <input type="text" class="form-control" placeholder="예금주를 입력하세요." v-model="userData.accountName" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label">계좌번호</label>
                                    <div class="col-sm-4">
                                        <input type="text" class="form-control" placeholder="계좌번호를 입력하세요." v-model="userData.account" />
                                    </div>
                                </div>
                                -->
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="boxwrap">
                        <div class="title">
                            <h3 class="text-left">사업자 정보</h3>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <!--
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label">대표자 이름</label>
                                    <div class="col-sm-4">
                                        <input type="text" class="form-control" placeholder="대표자 이름을 입력하세요." />
                                    </div>
                                    <label class="col-sm-2 col-form-label">대표자 전화번호</label>
                                    <div class="col-sm-4">
                                        <input type="text" class="form-control" placeholder="대표자 전화번호를 입력하세요." />
                                    </div>
                                </div>
                                -->
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label">사업자등록번호</label>
                                    <div class="col-sm-4">
                                        <input type="number" class="form-control" placeholder="사업자등록번호를 입력하세요." v-model="userData.businessNumber" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label">업태</label>
                                    <div class="col-sm-4">
                                        <input type="text" class="form-control" placeholder="업태를 입력하세요." v-model="userData.businessType" />
                                    </div>
                                    <label class="col-sm-2 col-form-label">업종</label>
                                    <div class="col-sm-4">
                                        <input type="text" class="form-control" placeholder="업종을 입력하세요." v-model="userData.businessItem" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label">사업장 주소</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" placeholder="사업장 주소를 입력하세요." v-model="userData.address" /><br>
                                        <input type="text" class="form-control" placeholder="사업장 상세 주소를 입력하세요." v-model="userData.addressDetail" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="boxwrap">
                        <div class="title">
                            <h3 class="text-left">비밀번호 변경</h3>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label">변경여부</label>
                                    <div class="col-sm-4">
                                        <div class="custom-control custom-control-inline" 
                                        style="padding-top: 10px;">
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" class="custom-control-input" id="isPasswordChange" :checked="isPasswordChange" @change="onClickIsPasswordChange" />
                                                <label class="custom-control-label" for="isPasswordChange">비밀번호를 변경합니다.</label>
                                            </div>
                                        </div>
                                    </div>
                                    <label class="col-sm-2 col-form-label">변경할 비밀번호</label>
                                    <div class="col-sm-4">
                                        <form>
                                        <input type="password" class="form-control" placeholder="변경할 비밀번호를 입력하세요." :disabled="!isPasswordChange" v-model="userData.password" autocomplete="off" />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </template>
            <template v-slot:footer>
                <button type="button" class="btn btn-primary" @click="onClickContentSave">변경 저장</button> 
                <button type="button" class="btn btn-secondary" @click="onClickClose">닫기</button>
            </template>
        </modal>

        <!--확인-->
        <modal-content-save 
            ref="modalContentSave" 
            @onCloseContentSave="onCloseContentSave"
        ></modal-content-save>


    </div>
</template>

<script>
import Modal from '../Modal'

import MContentSave from './MContentSave'
import * as DateUtils from '../../utils/date-utils'
import { mapState } from 'vuex'
import axios from 'axios'

export default {
    components: {
        Modal,
        'modal-content-save'  : MContentSave,
    },
    data() {
        return {
            isView : false,
            userInfo : {},
            userData : {},
            isPasswordChange: false
        }
    },
    created() {
        const userInfo = this.$store.getters.getUserInfo()
        if (_.isEmpty(userInfo)) {
            this.$router.replace({
                path: '/'
            })
        }
        this.userInfo = userInfo
        //console.log(this.userInfo)
    },
    computed: {
        ...mapState(['domainUrl'])
    },
    methods: {
        onShow() {
            this.getTMData()
            this.$refs.modal.onShow()
        },
        /**
         * 모달닫기
         *
         * @method onClickClose
         */
        onClickClose() {
            this.$refs.modal.onClose()
        },

        /**
         * 데이터를 가져오는 함수
         *
         * @method getShopSetting
         * @param {boolean} isSearchBtnClick 검색 버튼 눌렀는지 여부
         */
         getTMData() {
            this.userData = {}
            this.$connect(
				`/api/member/${this.userInfo.seq}`,
                'GET'
            )
            .then(bodyData => {
                this.userData = bodyData.list
                this.userData.password = ''
            })
            .catch(error => {
                console.log("error", error);
            })
        },

        /**
         * 데이터를 가져오는 함수
         *
         * @method onClickContentSave
         * @param {boolean} isSearchBtnClick 검색 버튼 눌렀는지 여부
         */
        onClickContentSave() {
            this.$refs.modalContentSave.onShow(this.userData)
        },
        /**
         * 상세보기 모달이 닫힐 때 실행되는 함수
         *
         * @method onCloseContentDetail
         * @param {Object} payload 상세보기 모달에서 전달된 데이터
         */
         onCloseContentSave(payload) {
            if (payload.isUpdate) {
                this.getTMData()
            }
        },

        /**
         * 주어진 ISO 형식의 날짜 문자열을 "YYYY-MM-DD HH:mm:ss" 형식으로 변환하는 메서드
         *
         * @method onFormatDateYmd
         * @param {string} value 날짜 문자열
         * @returns {string} 포맷팅된 날짜 문자열
         */
         onFormatDateYmd(dateString) {
            return DateUtils.formatDateKor(dateString)
        },

        onClickIsPasswordChange(){
            this.isPasswordChange = !this.isPasswordChange
        },

        async onClickLogoFileUpload(){
            try {
                let uploadFiles = this.$refs.logoFile.files;
                let formData = new FormData();
                formData.append("file", uploadFiles[0]);

                const response = await axios.post(`${this.domainUrl}/api/file/upload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${this.userInfo.userToken}`
                    }
                });
                //console.log(response);
            } catch (error) {
                console.log(error);
            }
        },

        onGetRole(item){
            switch (item) {
                case 'ADMIN':
                return '관리자';
                case 'MANAGER':
                return '상점관리자';
                case 'USER':
                return '사용자';
                default:
                return '알 수 없음';
            }
        }
    },
}
</script>