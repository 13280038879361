import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        //domainUrl: 'http://1.241.84.248:8080', //개발용
        //imageUrl : 'http://1.241.84.248:8080', //개발용

        domainUrl: 'https://goldcarriageapi.kr', //운영
        imageUrl : 'https://goldcarriageapi.kr', //운영
        
        isLoading: false, // 로딩 뷰를 띄울지 유무
        regx: {
            'onlyNum': /^[0-9]*$/,
            'pwCheck': /^(?=.*[a-zA-Z])((?=.*\d)|(?=.*\W)).{8,16}$/
        },
        menus: {
            rootDepth: 2,
            depth01: 0,
            depth02: 0
        },
        settingCheck : false,
        channel : "SUPER",    //SUPER
        serviceType : {
            '1' : '베이직',
            '2' : '스탠다드',
            '3' : '프리미엄',
            '4' : '언리미티드'
        },
        role : {
            'ADMIN'   : '관리자',
            'MANAGER' : '상점관리자',
            'USER'    : '사용자'
        }
    },

    mutations: {
        startLoading(state) {
            state.isLoading = true;
        },
        stopLoading(state) {
            state.isLoading = false;
        },
        SET_MENUS(state, payload) {
            state.menus = {
                rootDepth: payload.rootDepth,
                depth01: payload.depth01,
                depth02: payload.depth02
            };
        },
        SET_SETTING_CHECK(state, payload) {
            state.settingCheck = payload;
        },
    },

    actions: {
        setMenus({ commit }, payload) {
            const {
                rootDepth,
                depth01,
                depth02
            } = payload;
            commit('SET_MENUS', {
                rootDepth,
                depth01,
                depth02
            })
        },
        cleanMenus({ commit }) {
            commit('SET_MENUS', {
                rootDepth: 2,
                depth01: 0,
                depth02: 0
            })
        },
        setSettingCheck({ commit }, payload) {
            commit('SET_SETTING_CHECK', payload)
        }
    },

    getters: {
        /**
         * 관리자 권한인지 체크
         * 
         * @method getIsAdminAuth
         * @return {Boolean} result true 관리자, false 관리자 아님
         */
        getIsAdminAuth: () => () => {
            let userInfo = window.sessionStorage.getItem('userInfo')
            userInfo = userInfo === null ? {} : JSON.parse(userInfo)

            return userInfo.roleNm === 'ADMIN'
        },
        /**
         * 사용자 정보를 가져오는 함수
         * 
         * @method getUserInfo
         * @return {Object} result 사용자 정보
         */
        getUserInfo: () => () => {
            let userInfo = window.sessionStorage.getItem('userInfo')
            userInfo = userInfo === null ? {} : JSON.parse(userInfo)

            return userInfo
        },

        getMenus: () => () => {
            return state.menus
        },

        getSettingCheck: () => () => {
            return state.settingCheck
        },

        getChannel: (state) => {
            return state.channel
        },
        getServiceType: (state) => {
            return state.serviceType
        },
        getRole: (state) => {
            return state.role
        },
    },
    plugins: [
        // Vuex 상태를 세션 스토리지에 저장하는 플러그인을 추가합니다.
        createPersistedState({
            storage: window.sessionStorage, // 세션 스토리지 사용
            key: ['superadmin'],
            path: ['menus'], // 특정 모듈 상태만 저장
            removeItemOnceOnSessionExpired: true // 세션이 만료될 때 해당 아이템을 삭제
        })
    ],
    beforeCreate() {
        // 스토어 초기화 전 세션 스토리지 비우기
        window.sessionStorage.clear();
    }
})