//공통(최상위)
import Vue from 'vue'
import store from './store'
import Router from 'vue-router'
import _ from 'lodash'  // lodash 추가
Vue.use(Router)

//components
import Login from './views/Login'
import Main from './views/Main'
import Dashboard from './views/Dashboard'

import MemberList from './views/Member/MemberList'
import MessageList from './views/Member/MessageList'
import MemberSendMessageList from './views/Member/MemberSendMessageList'

import AdditionalServiceList from './views/AdditionalService/AdditionalServiceList'

import Mypage from './views/Setting/Mypage'

import AccountBankHelper from './views/Help/AccountBankHelper'

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login
    },
    {
      path: '/main',
      name: 'name',
      component: Main,
      //meta: { requiresAuth: true },
      children: [
        // 대시보드
        {
          path: 'dashboard',
          name: 'dashboard',
          component: Dashboard
        },
        {
          path: 'memberList',
          name: 'memberList',
          component: MemberList
        },
        {
          path: 'mypage',
          name: 'mypage',
          component: Mypage
        },
        {
          path: 'messageList',
          name: 'messageList',
          component: MessageList
        },
        {
          path: 'memberSendMessageList',
          name: 'memberSendMessageList',
          component: MemberSendMessageList
        },
        {
          path: 'additionalServiceList',
          name: 'additionalServiceList',
          component: AdditionalServiceList
        }
      ]
    },
    {
      path: '/accountBankHelper',
      name: 'accountBankHelper',
      component: AccountBankHelper
    }
  ]
})

/*
router.beforeEach((to, from, next) => {
  const userInfo = store.getters.getUserInfo()
  if (to.matched.some(record => record.meta.requiresAuth)) {  
    if(_.isEmpty(userInfo)){
      next({
        path: '/',
        //query: { redirect: to.fullPath }
      })
    }else{
      next()
    }
  } else {
      next()
  }
})
*/

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta && to.meta.title ? to.meta.title : "골드오더 SUPER ADMIN";
  });
});

export default router;