import store from '../store'
import axios from 'axios'
import router from '../router'

/**
 * ESS Http 플러그인
 * 
 * @method install
 */
const EssHttp = {
    install(Vue) {
        Vue.customAxios = axios.create({
            timeout: 600 * 10000,
            crossdomain: true,
            withCredentials: false,
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                //'Content-Type': 'multipart/form-data',
            }
        })

        /**
         * 전달받은 Response의 Head, Body를 검사하는 함수
         * 
         * @method response
         * @param {Object} res Response 객체
         * @return {Object} result Promise 정보
         */
        Vue.response = (res) => {
            return new Promise((resolve, reject) => {
                //console.log("res:", res)
                let responseData = res.data

                if(responseData.success || responseData.length > 0){
                    resolve(responseData.body)
                }else{
                    //reject(new Error(`${responseData.errMsg}[${responseData.err_code}]`))
                    reject(new Error(`${responseData.errMsg}`))
                }
            })
        }

        /**
         * AXIOS로 통신하는 함수
         * 
         * @param {String} paramUrl 전송 할 URL
         * @param {String} paramMethod 전송 할 메소드 타입(GET, POST, PUT, DELETE)
         * @param {Object} params 전송 할 파라미터
         * @return {Object} result Promise 정보
         */
        Vue.prototype.$connect = (paramUrl, paramMethod, params = {}) => {
            return new Promise((resolve, reject) => {
                store.commit('startLoading')

                let userInfo = store.getters.getUserInfo()
                let header = {
                    'Authorization': `Bearer ${userInfo.userToken}`
                }
                //console.warn(header)
                if (userInfo.userToken === undefined) delete header['Authorization']

                Vue.customAxios({
                        method: paramMethod,
                        url: `${store.state.domainUrl}${paramUrl}`,
                        data: params,
                        headers: header
                    })
                    .then(res => Vue.response(res))
                    .then(bodyData => {
                        store.commit('stopLoading')
                        resolve(bodyData)
                    })
                    .catch(error => {
                        reject(new Error(error))
                        store.commit('stopLoading')
                        let errorMsg = error
                        if (error.response !== undefined){
                          if(error.response.status === 401 ||  error.response.status === 403) {
                            errorMsg = '사용자 인증 시간이 만료되었습니다. 로그인 후 다시 시도해 주시기 바랍니다.'
                            localStorage.removeItem('userInfo');
                            sessionStorage.removeItem('userInfo');
                            
                            router.push({ path: '/' })
                          }
                        }
                        alert(errorMsg)
                        //console.log("error", error.response);
                    })
            })
        }

        /**
         * AXIOS로 통신하는 함수(로딩이미지제거)
         * 
         * @param {String} paramUrl 전송 할 URL
         * @param {String} paramMethod 전송 할 메소드 타입(GET, POST, PUT, DELETE)
         * @param {Object} params 전송 할 파라미터
         * @return {Object} result Promise 정보
         */
        Vue.prototype.$connectStatus = (paramUrl, paramMethod, params = {}) => {
            return new Promise((resolve, reject) => {
                let userInfo = store.getters.getUserInfo()
                //console.log("userInfo:", userInfo); 
                //console.log("userInfo.userToken:", userInfo.userToken);
                let header = {
                    'Authorization': `Bearer ${userInfo.userToken}`
                }

                if (userInfo.userToken === undefined) delete header['Authorization']

                Vue.customAxios({
                        method: paramMethod,
                        url: `${store.state.domainUrl}${paramUrl}`,
                        data: params,
                        headers: header
                    })
                    .then(res => Vue.response(res))
                    .then(bodyData => {
                        resolve(bodyData)
                    })
                    .catch(error => {
                        reject(new Error(error))

                        let errorMsg = error

                        if (error.response !== undefined && error.response.status === 401) {
                            errorMsg = '사용자 인증 시간이 만료되었습니다. 로그인 후 다시 시도해 주시기 바랍니다.'
                            router.push({
                                path: '/login'
                            })
                        }

                        alert(errorMsg)
                    })
            })
        }




        /**
         * 파일 다운로드 함수
         * 
         * @method download
         * @param {String} fileName 파일 명
         * @param {Object} binaryData 파일 바이너리 데이터
         * @param {String} contentType 파일 컨텐츠 타입
         */
        Vue.prototype.$download = (fileName, binaryData) => {
            let binaryString = window.atob(binaryData);
            let binaryLen = binaryString.length;
            let bytes = new Uint8Array(binaryLen);
            for (let index = 0; index < binaryLen; index++) {
                let ascii = binaryString.charCodeAt(index);
                bytes[index] = ascii;
            }

            const url = window.URL.createObjectURL(new Blob([bytes]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${fileName}`); //or any other extension
            document.body.appendChild(link);
            link.click();
        }

    }
}

export default EssHttp