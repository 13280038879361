<!-- 상세보기모달 -->
<template>
    <div>
        <modal ref="modal" :title="`문자 전송 상세보기`" :width="800">
            <template v-slot:body>
                <div class="row">
                    <div class="col-12">
						<div class="form-group row">
							<label for="selResult" class="col-sm-2 col-form-label">상점 아이디</label>
							<div class="col-sm-10">
								<span class="form-control">{{ modalData.ownerId }}</span>
							</div>
						</div>
						<div class="form-group row">
							<label for="selResult" class="col-sm-2 col-form-label">주문상태</label>
							<div class="col-sm-10">
								<span class="form-control">{{ modalData.status }}</span>
							</div>
						</div>
                        <div class="form-group row">
							<label for="selResult" class="col-sm-2 col-form-label">주문자전화번호</label>
							<div class="col-sm-10">
								<span class="form-control">{{ modalData.phone }}</span>
							</div>
						</div>
						<div class="form-group row">
							<label for="selResult" class="col-sm-2 col-form-label">문자제목</label>
							<div class="col-sm-10">
								<span class="form-control">{{ modalData.subject }}</span>
							</div>
						</div>
						<div class="form-group row">
							<label for="selResult" class="col-sm-2 col-form-label">문자내용</label>
							<div class="col-sm-10">
								<span class="form-control" v-html="filterContents(modalData.content)" style="height: auto;"></span>
							</div>
						</div>
                        <div class="form-group row">
							<label for="selResult" class="col-sm-2 col-form-label">문자형태</label>
							<div class="col-sm-10">
								<span class="form-control">{{ modalData.type }}</span>
							</div>
						</div>
                        <div class="form-group row">
							<label for="selResult" class="col-sm-2 col-form-label">발송비용</label>
							<div class="col-sm-10">
								<span class="form-control">{{ onNumberFormat(modalData.lmsUnitPrice) }}원</span>
							</div>
						</div>
                        <div class="form-group row">
							<label for="selResult" class="col-sm-2 col-form-label">발송일</label>
							<div class="col-sm-10">
								<span class="form-control">{{ onFormatDateYmd(modalData.createDatetime) }}</span>
							</div>
						</div>
					</div>
                </div>
            </template>
            <template v-slot:footer>
                <button type="button" class="btn btn-secondary" @click="onClickClose">닫기</button>
            </template>
        </modal>

    </div>
</template>

<script>
import Modal from '../Modal'
import * as DateUtils from '../../utils/date-utils'
import * as EtcUtils from '../../utils/etc-utils'

export default {
    components: {
        Modal
    },
    data() {
        return {
            modalData   : {},
            isUpdate    : false
        }
    },
    methods: {
        onShow(payload) {
			this.modalData = JSON.parse(JSON.stringify(payload))
            this.$refs.modal.onShow()
        },

        getModalData() {
			this.$connect(
                `/api/message/${this.modalData.seq}`,
                'GET'
            )
            .then(bodyData => {
                this.modalData = bodyData.list;
            })
        },

        /**
         * 모달닫기
         *
         * @method onClickClose
         */
        onClickClose() {
            this.$refs.modal.onClose()
            this.$emit('onCloseMessageDetail', {
                isUpdate: this.isUpdate
            })
        },

		/**
         * 숫자를 천 단위로 구분하여 포맷팅
         *
         * @method onNumberFormat
         * @param {Number} num 포맷팅할 숫자
         * @return {String} result 천 단위로 구분된 문자열
         */
         onNumberFormat(num) {
            return num?EtcUtils.numberFormat(num):0
        },

        /**
         * 주어진 ISO 형식의 날짜 문자열을 "YYYY-MM-DD HH:mm:ss" 형식으로 변환하는 메서드
         *
         * @method onFormatDateYmd
         * @param {string} value 날짜 문자열
         * @returns {string} 포맷팅된 날짜 문자열
         */
         onFormatDateYmd(dateString) {
            return DateUtils.formatDateYmd(dateString)
        },

        filterContents(string) {
            //console.log("string:", string);
            if(string){
                return string.replace(/(?:\r\n|\r|\n|\\n)/g, '<br>').trim();
            }
        },

    },
    
}
</script>