import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import SlideUpDown from 'vue-slide-up-down'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import HttpRequest from './plugins/http-request'
import VueCookies from 'vue-cookies'
import { VTooltip, VPopover } from 'v-tooltip'
import 'codemirror/lib/codemirror.css'
import '@toast-ui/editor/dist/toastui-editor.css'
import '@toast-ui/editor/dist/i18n/ko-kr';
import { Editor, Viewer } from '@toast-ui/vue-editor'
import Notifications from 'vue-notification'
import Multiselect from 'vue-multiselect'
import "vue-multiselect/dist/vue-multiselect.min.css"
import SlVueList from 'sl-vue-tree'
import FullCalendar from '@fullcalendar/vue';
import Clipboard from 'v-clipboard'

Vue.use(VueCookies)
Vue.use(HttpRequest)

Vue.config.productionTip = false
//Vue.use(VueLodash, { name: 'lodash' }) // options is optional
Vue.use(VueLodash, { name: 'custom' , lodash: lodash })
Vue.use(Notifications)
Vue.use(SlVueList)
Vue.use(Clipboard);

Vue.directive('tooltip', VTooltip)
Vue.component('v-popover', VPopover)
Vue.component('multiselect', Multiselect)

Vue.component('slide-up-down', SlideUpDown);
Vue.component('editor', Editor)
Vue.component('viewer', Viewer)

Vue.component('FullCalendar', FullCalendar);

VueCookies.config('7d') // 쿠키 7일 저장

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
