import * as DateUtils from './utils/date-utils'
import { _ } from 'core-js';


export const tableMixin = {
  data() {
    return {
      searchData: {
        currentPage: 1, // 현재 페이지
        startDate: DateUtils.nowDate(), // 검색 시작 날짜
        endDate: DateUtils.nowDate(), // 검색 종료 날짜
        search: {
          searchTxt: '', // 검색어
          selectValue: '', // 검색 타입
        }
      },
      isActiveDetailSearch: false, // 상세 검색 보여줄지 유무
      viewKey: Date.now(), // 컴포넌트 키
      pagingViewKey: Date.now(), // 페이징 초기화 컴포넌트 키
      selectedItems: [], // 선택된 상품목록
      allSelected: false, // 전체 상품목록 선택 유무
      tableItems: [], // 조회한 상품 목록
      inTableItems: [], // 조회환 상품 내의 조회 목록
      dashboardStatus: {}, // 대시 보드 상태
      totalCount: 0, // 총 검색 결과
      dataSearchDate: '', // 데이터 조회 시점
      tableItemPK: 'seq', // 테이블의 PK
    }
  },
  methods: {
    /**
     * 테이블 아이템의 PK를 지정하는 함수
     * 
     * @param {String} keyName PK로 지정할 키 명
     */
    setTableItemPK(keyName = 'seq') {
      this.tableItemPK = keyName
    },
    /**
     * 검색 파라미터를 가져오는 함수
     * 
     * @method getSearchParam
     * @param {Array} checkKeys 파라미터로 만들 체크박스 키 목록
     * @return {String} result 검색 파라미터 정보
     */
    getSearchParam(checkKeys = []) {
      let param = `?searchStartDt=${this.getStartDate()}&searchEndDt=${this.getEndDate()}`
      param += this.searchData.search.searchTxt.length === 0 ? '' : `&searchText=${this.searchData.search.searchTxt}&searchType=${this.searchData.search.selectValue}`

      checkKeys.forEach(v => {
        param += this.searchData[v] === undefined || this.searchData[v].selecteds.length === 0 ?
          '' : `&${v}=${this.searchData[v].selecteds}`
      })

      return param
    },
    /**
     * 검색 파라미터를 가져오는 함수
     * 
     * @method getSearchParamArray
     * @param {Array} checkKeys 파라미터로 만들 체크박스 키 목록
     * @return {String} result 검색 파라미터 정보
     */
     getSearchParamArray(checkKeys = []) {
      let param = `?searchStartDt=${this.getStartDate()}&searchEndDt=${this.getEndDate()}`
      param += this.searchData.search.searchTxt.length === 0 ? '' : `&searchText=${this.searchData.search.searchTxt}&searchType=${this.searchData.search.selectValue}`
      checkKeys.forEach(v => {
        if(this.searchData[v] === undefined || this.searchData[v].selecteds.length === 0){
          param += ''
        }else{
          this.searchData[v].selecteds.forEach(s => {
            param += `&${v}=${s}`
          })
        }
      })

      return param
    },
    /**
     * 상태 아이콘 클릭 함수
     * 
     * @method onTMClickStatus
     */
    onTMClickStatus(payload) {
      console.log('TableMixin onTMClickStatus: ', payload)
    },
    /**
     * 상세 검색 클릭 함수
     * 
     * @method onTMClickDetailSearch
     */
    onTMClickDetailSearch() {
      this.isActiveDetailSearch = !this.isActiveDetailSearch
      //console.log(this.isActiveDetailSearch)
    },
    /**
     * 뷰 키를 변경하는 함수
     * 
     * @method onTMChangeViewKey
     */
    onTMChangeViewKey() {
      console.log('TableMixin onTMChangeViewKey')
      this.viewKey = Date.now()
    },
    /**
     * 변수들을 초기화하는 함수
     * 
     * @method onTMClear 
     */
    onTMClear() {
      console.log('TableMixin 오버라이딩을 해주시기 바랍니다.')
    },
    /**
     * 검색어 셀렉트 박스 셋팅 함수
     * 
     * @method setTMSelectSearchBox
     * @param {Object} payload 검색어 셀렉트 박스 정보
     */
    setTMSelectSearchBox(payload) {
      if (!this.searchData[payload.name]) {
        this.searchData[payload.name] = {}
      }

      this.searchData[payload.name].selectValue = payload.selectValue
      this.searchData[payload.name].searchTxt = payload.searchTxt

      if (payload.isEnter !== undefined && payload.isEnter === true) {
        this.getTMData(true)
      }

      // console.log('TableMixin:: setTMSelectSearchBox: ', this.searchData)
    },
    /**
     * 그룹 체크박스 셋팅 함수
     * 
     * @emthod setTMGroupCheckbox
     * @param {Object} payload 체크 박스 그룹 정보
     */
    setTMGroupCheckbox(payload) {
      let groupCheckboxName = payload.name
      //console.log(payload)
      if (!this.searchData[payload.name]) {
        this.searchData[groupCheckboxName] = []
      }

      this.searchData[groupCheckboxName].isSelectedAll = payload.isSelectedAll
      this.searchData[groupCheckboxName].selecteds = payload.selecteds

      //입고조회 전체조회시 기능등급 디폴트값으로 변경
      if(payload.name === "stmGrade" && payload.isSelectedAll === false && payload.selecteds.length === 0){
        this.inGrade = '99'
      }

      //console.log('TableMixin:: setTMGroupCheckbox: ', this.searchData)
    },
    /**
     * 페이지 데이터를 가져오는 함수
     * 
     * @method getTMPageData
     * @param {Number} payload 페이지 정보
     */
    getTMPageNum(payload) {
      this.searchData.currentPage = payload.pageNum
      //console.log('TableMixin:: getTMPageNum: ', this.searchData)

      if (payload.isGetData) {
        this.getTMData()
      }
    },
    /**
     * 데이터를 가져오는 함수
     * 
     * @method getTMData
     * @param isSearchBtnClick 검색 버튼 클릭 유무(true 검색버튼 클릭)
     */
    getTMData(isSearchBtnClick = false) {

      console.log('TableMixin:: getTMData: ', this.searchData)
    },
    /**
     * 데이트 값을 셋팅하는 함수
     * 
     * @method setTMDate
     * @param {Object} payload 데이트 정보
     */
    setTMDate(payload) {
      this.searchData.startDate = ''
      this.searchData.endDate = ''

      if (payload.startDate) this.searchData.startDate = payload.startDate.substr(0, 10)
      if (payload.endDate) this.searchData.endDate = payload.endDate.substr(0, 10)
    },
    /**
     * 시작 날짜를 가져오는 함수
     * 
     * @method getStartDate
     * @return {String} result 시작 날짜
     */
    getStartDate() {
      return this.searchData.startDate.substring(0, 10).replace(/-/gi, '')
    },
    /**
     * 종료 날짜를 가져오는 함수
     * 
     * @method getEndDate
     * @return {String} result 종료 날짜
     */
    getEndDate() {
      return this.searchData.endDate.substring(0, 10).replace(/-/gi, '')
    },
    /**
     * 날짜 비교 함수
     * 
     * @method tmCompareDate
     * @result {Boolean} result true 정상, false 시작 날짜가 종료 날짜보다 큼
     */
    tmCompareDate() {
      let result = true
      let startDate = new Date(this.searchData.startDate)
      let endDate = new Date(this.searchData.endDate)

      if (startDate > endDate) {
        alert('시작 날짜는 종료 날짜보다 클 수 없습니다.')
        result = false
      }

      return result
    },
    /**
     * 테이블 체크박스 전체 선택 변경 함수
     * 
     * @method onChangeAllSelected
     */
    onChangeAllSelected() {
      console.log('onChangeAllSelected')

      this.selectedItems = [];

      if (this.allSelected) {
        this.tableItems.forEach(item => {
          this.selectedItems.push(item[this.tableItemPK])
        })
      }

      console.log('선택된 목록:', this.selectedItems)
    },
    /**
     * 테이블 체크박스 선택 변경 함수
     */
    onChangeSelected() {

      console.log('선택된 목록:', this.selectedItems)

      if (this.selectedItems.length === 0) {
        this.allSelected = false
      }

      if (this.selectedItems.length === this.tableItems.length) {
        this.allSelected = true
      }
    }
  }
}