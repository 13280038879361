<!-- 상세보기모달 -->
<template>
    <div>
        <modal ref="modal" :title="mode === 's' ? '문자 내용 등록' : '문자 내용 수정'" :width="800">
            <template v-slot:body>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group row">
							<label class="col-sm-2 col-form-label">상점아이디</label>
							<div class="col-sm-10">
								<input type="text" class="form-control" placeholder="상점아이디를 입력하세요." v-model="modalData.ownerId" />
							</div>
						</div>
                        <div class="form-group row">
							<label class="col-sm-2 col-form-label">주문상태</label>
							<div class="col-sm-10">
								<select class="form-control" v-model="modalData.status" placeholder="주문상태를 선택하세요.">
                                    <template v-for="(item, index) in statusList">
									<option :value="item.status" :key="index">{{ item.status }}</option>
                                    </template>
								</select>
							</div>
						</div>
                        <div class="form-group row">
							<label class="col-sm-2 col-form-label">문자제목</label>
							<div class="col-sm-10">
								<input type="text" class="form-control" placeholder="문자제목을 입력하세요." v-model="modalData.subject" />
							</div>
						</div>
						<div class="form-group row">
							<label class="col-sm-2 col-form-label">문자내용</label>
							<div class="col-sm-10">
								<textarea style="height: 100px" type="text" class="form-control" placeholder="문자내용을 입력해주세요." 
                                v-model="modalData.content"
								/>
							</div>
						</div>
                        <div class="form-group row">
							<label class="col-sm-2 col-form-label">문자형태</label>
							<div class="col-sm-10">
								<div class="custom-control custom-radio custom-control-inline">
                                    <input
                                        type="radio"
                                        name="saleStatus"
                                        class="custom-control-input"
                                        id="saleStatus1"
                                        value="SMS"
                                        v-model="modalData.type"
                                        checked
                                    />
                                    <label class="custom-control-label" for="saleStatus1">SMS</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input
                                        type="radio"
                                        name="saleStatus"
                                        class="custom-control-input"
                                        id="saleStatus2"
                                        value="LMS"
                                        v-model="modalData.type"
                                    />
                                    <label class="custom-control-label" for="saleStatus2">LMS</label>
                                </div>
							</div>
						</div>
					</div>
                </div>
            </template>
            <template v-slot:footer>
				<button type="button" class="btn btn-primary" @click="onClickOK">저장</button>
                <button type="button" class="btn btn-secondary" @click="onClickClose">닫기</button>
            </template>
        </modal>

    </div>
</template>

<script>
import Modal from '../Modal'
import sha512 from 'js-sha512';
import { mapState } from 'vuex'
import axios from 'axios'

export default {
    components: {
        Modal
    },
    data() {
        return {
            modalData   : [],
            isUpdate    : false,
			mode        : 's',
            userInfo    : {},
            statusList  : []
        }
    },
    created() {
        const userInfo = this.$store.getters.getUserInfo()
        if (_.isEmpty(userInfo)) {
            this.$router.replace({
                path: '/'
            })
        }
        this.userInfo = userInfo
    },
    computed: {
        ...mapState(['domainUrl'])
    },
    methods: {
        onShow(payload) {
            this.getStatus()

			if(payload && payload.seq){
                this.mode = 'e'

				this.modalData = JSON.parse(JSON.stringify(payload))
			}else{
                this.modalData   = []
                this.lastData = []
			}

            //console.log("this.modalData", this.modalData)
            this.$refs.modal.onShow()
        },

        /**
         * 모달닫기
         *
         * @method onClickClose
         */
        onClickClose() {
            this.$refs.modal.onClose()
            this.$emit('onCloseMessageSave', {
                isUpdate: this.isUpdate
            })
        },

		onClickOK() {

            if(!this.modalData.status){
                alert("주문상태를 선택해주세요.")
                return false
            }
            if(!this.modalData.subject){
                alert("문자제목을 입력해주세요.")
                return false
            }
            if(!this.modalData.content){
                alert("문자내용을 입력해주세요.")
                return false
            }
            if(!this.modalData.type){
                alert("문자형태을 입력해주세요.")
                return false
            }

            let param = this.modalData
            param = {
                "ownerId"      : this.modalData.ownerId,
                "status"       : this.modalData.status,
                "type"         : this.modalData.type,
                "subject"      : this.modalData.subject,
                "content"      : this.modalData.content,
                "createUserId" : this.modalData.createUserId?this.modalData.createUserId:this.userInfo.id
			}

            if(this.mode === 'e'){
                param.seq          = this.modalData.seq
                param.modifyUserId = this.userInfo.id
            }

            this.$connect(
                `/api/message`,
                'POST', param
            )
            .then(bodyData => {
                alert("문자내용이 " + (this.mode === 's'?"등록":"수정") + " 되었습니다.")
				this.isUpdate = true
				this.onClickClose()
            })

		},

        getStatus(){
            this.$connect(
                `/api/orderStatus/listAll`,
                'GET'
            )
            .then(bodyData => {
                this.statusList = bodyData.list
            })
        }

    },
}
</script>